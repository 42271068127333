/* Here we define our url related data.
    Details of each object:
      routerPage: used in routes file as the path field
      pathFull: actual path to the page; this should be used when linking to a page
      pathName: in most circumstances, this should be the same as pathFull. this is used for things
        like:  "pathname === sitedata.url.app.groupclassroomDt.pathName"
        Where we are using react router to check if the current path is equal. If a path contains
        a query string like /classroom?uuid= the *path* will not  include the query string.
      backTo: what page you want the "back" button to go to. should be null if the page does
        not have a back button
      allowVersionRefresh: boolean, true meaning we do allow our automatic version update code
        to refresh the page
*/

export const url = {
  // locations inside of our app
  app: {
    // login
    login: `${process.env.REACT_APP_LEGACYURL}/studenthome/`,
    createAccount: `${process.env.REACT_APP_LEGACYURL}/en/free-trial/`,
    // home
    home: '/home',
    homeDt: {
      routerPage: 'home',
      pathFull: '/home',
      pathName: '/home',
      backTo: null,
      allowVersionRefresh: true,
    },
    // messages
    messages: '/home/messages',
    messagesDt: {
      routerPage: 'messages',
      pathFull: '/home/messages',
      pathName: '/home/messages',
      backTo: '/home',
      allowVersionRefresh: true,
    },
    // privatelessonsMycalendar
    privatelessonsMycalendar: '/home/myschedule',
    privatelessonsMycalendarDt: {
      routerPage: 'myschedule',
      pathFull: '/home/myschedule',
      pathName: '/home/myschedule',
      backTo: '/home',
      allowVersionRefresh: true,
    },
    // teachersDiscover
    teachersDiscover: '/home/discoverteachers',
    teachersDiscoverDt: {
      routerPage: 'discoverteachers',
      pathFull: '/home/discoverteachers',
      pathName: '/home/discoverteachers',
      backTo: '/home',
      allowVersionRefresh: true,
    },
    // privatelessonsTeacherProfile
    privatelessonsTeacherProfile: '/home/tchschedule?id=',
    privatelessonsTeacherProfileDt: {
      routerPage: 'tchschedule',
      pathFull: '/home/tchschedule?id=',
      pathName: '/home/tchschedule',
      backTo: '/home',
      allowVersionRefresh: true
    },
    // teachersProfile
    teachersProfile: '/home/tchprofile?id=',
    teachersProfileDt: {
      routerPage: 'tchprofile',
      pathFull: '/home/tchprofile?id=',
      pathName: '/home/tchprofile',
      backTo: '/home',
      allowVersionRefresh: true
    },
    // privateClassroom
    privateClassroom: `${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom&lesson_id=`,
    privateClassroomDt: {
      routerPage: 'studenthome',
      pathFull: `${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom&lesson_id=`,
      pathName: `${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom&lesson_id=`,
      backTo: null,
      allowVersionRefresh: false
    },

    // group lessons
    grouplsnUpcomingDt: {
      routerPage: 'group',
      pathFull: '/home/group',
      pathName: '/home/group',
      backTo: '/home',
      allowVersionRefresh: true,
    },

    groupclassroomDt: {
      routerPage: 'classroom',
      pathFull: '/home/group/classroom?uuid=',
      pathName: '/home/group/classroom',
      backTo: '/home/group',
      allowVersionRefresh: false,
    },
    // teachers
    teachersFeedback: '/teachers/givefeedback',
    teachersFeedbackDt: {
      routerPage: 'givefeedback',
      pathFull: '/teachers/givefeedback',
      pathName: '/teachers/givefeedback',
      backTo: '/teachers',
      allowVersionRefresh: false, // may be student is giving feedback to the teacher
    },

    // study
    practiceHome: '/practice',
    practiceHomeDt: {
      routerPage: 'practice',
      pathFull: '/practice',
      pathName: '/practice',
      backTo: null,
      allowVersionRefresh: true,
    },
    // practiceEasylistening
    practiceEasylistening: '/practice/easylistening',
    practiceEasylisteningDt: {
      routerPage: 'easylistening',
      pathFull: '/practice/easylistening',
      pathName: '/practice/easylistening',
      backTo: '/practice',
      allowVersionRefresh: false, // need to think if user is listening to available 
      // audio's
    },
    // practiceCurriculum
    practiceCurriculum: '/practice/curriculum',
    practiceCurriculumDt: {
      routerPage: 'curriculum',
      pathFull: '/practice/curriculum',
      pathName: '/practice/curriculum',
      backTo: '/practice',
      allowVersionRefresh: true,
    },

    // progress
    progressHomeDt: {
      routerPage: 'progress',
      pathFull: '/progress',
      pathName: '/progress',
      backTo: null,
      allowVersionRefresh: true,
    },

    // purchase home page
    purchaseHome: '/purchase',
    purchaseHomeDt: {
      routerPage: 'purchase',
      pathFull: '/purchase',
      pathName: '/purchase',
      backTo: null,
      allowVersionRefresh: false, // we need to think if user is in the middle of payment
    },
    // purchasePay
    purchasePay: '/purchase/pay?pid=',
    purchasePayDt: {
      routerPage: 'pay',
      pathFull: '/purchase/pay?id=',
      pathName: '/purchase/pay',
      backTo: '/purchase',
      allowVersionRefresh: false, // need to think what if user is in the middle of payment/filling 
      // his card info do we refresh it,?
    },
    // purchaseTip
    purchaseTip: '/purchase/tipteacher',
    purchaseTipDt: {
      routerPage: 'tipteacher',
      pathFull: '/purchase/tipteacher',
      pathName: '/purchase/tipteacher',
      backTo: '/purchase',
      allowVersionRefresh: false, // we need to think if user is giving tip and in 
      // the middle of payment
    },
    // purchaseHistory
    purchaseHistory: '/purchase/history',
    purchaseHistoryDt: {
      routerPage: 'history',
      pathFull: '/purchase/history',
      pathName: '/purchase/history',
      backTo: '/purchase',
      allowVersionRefresh: true,
    },
    // purchaseReciept
    purchaseReciept: 'purchase/history/reciept?id=',
    purchaseRecieptDt: {
      routerPage: 'reciept',
      pathFull: '/purchase/history/reciept?id=',
      pathName: '/purchase/history/reciept',
      backTo: '/purchase/history',
      allowVersionRefresh: true,
    },
    // more
    moreHome: '/more',
    moreHomeDt: {
      routerPage: 'more',
      pathFull: '/more',
      pathName: '/more',
      backTo: null,
      allowVersionRefresh: true,
    },
    // moreSettings
    moreSettings: '/more/mysettings',
    moreSettingsDt: {
      routerPage: 'mysettings',
      pathFull: '/more/mysettings',
      pathName: '/more/mysettings',
      backTo: '/more',
      allowVersionRefresh: true,
    },
    // moreFiles
    moreFiles: '/more/myfiles',
    moreFilesDt: {
      routerPage: 'myfiles',
      pathFull: '/more/myfiles',
      pathName: '/more/myfiles',
      backTo: '/more',
      allowVersionRefresh: true,
    },
    // moreAvtest
    moreAvtest: `${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom/test/`,
    moreAvtestDt: {
      routerPage: 'avtest',
      pathFull: `${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom/test/`,
      pathName: `${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=classroom/test/`,
      backTo: null,
      allowVersionRefresh: true,
    },
    // moreEmailprefs
    moreEmailprefs: '/emailprefs?uuid=',
    moreEmailprefsDt: {
      routerPage: 'emailprefs',
      pathFull: '/emailprefs?uuid=',
      pathName: '/emailprefs',
      backTo: null,
      allowVersionRefresh: true,
    },
  },

  // mainWebsite
  mainWebsite: process.env.REACT_APP_MKTURL,
  mainWebsiteDt: {
    routerPage: 'process.env.REACT_APP_MKTURL',
    pathFull: process.env.REACT_APP_MKTURL,
    pathName: process.env.REACT_APP_MKTURL,
    backTo: null,
    allowVersionRefresh: true,
  }
};
