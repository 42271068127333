import React from 'react';
import {
  Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { PrivateUpcoming } from './components/PrivateUpcoming/PrivateUpcoming0';
import { PrivateSchedLsn } from './components/PrivateSchedLsn/PrivateSchedLsn0';
import { Messages } from './components/Messages';
import { DiscoverTeachers } from './components/DiscoverTeachers';
import { DiscoverTeachersNotsubscribed } from './components/DiscoverTeachersNotsubscribed';
import { GroupClassesNotsubscribed } from './components/GroupClassesNotsubscribed';
import { GroupClassesSubscribed } from './components/GroupClassesSubscribed';

interface Props {
  isSubscribed: boolean,
}

// entire home page layout
export const LayoutPosttrial2 = ({ isSubscribed }: Props) => {
  // eslint-disable-next-line
  const x = 1;

  return (
    <Grid2Ct>
      {/* TODO: notification - unread messages */}

      {/* if user IS subscribed: group classes, discover teachers */}
      {isSubscribed ? (
        <Grid2 xs={12}>
          <Grid2Ct>
            <GroupClassesSubscribed />
            <DiscoverTeachers />
          </Grid2Ct>
        </Grid2>
      ) : (
        <div />
      )}

      {/* upcoming private lessons, schedule a private lesson */}
      <Grid2 xs={12}>
        <Grid2Ct>
          <PrivateUpcoming />
          <PrivateSchedLsn />
        </Grid2Ct>
      </Grid2>

      {/* if the user is NOT subscribed: group classes, discover teachers */}
      {!isSubscribed ? (
        <Grid2 xs={12}>
          <Grid2Ct>
            <GroupClassesNotsubscribed />
            <DiscoverTeachersNotsubscribed />
            <Messages />
          </Grid2Ct>
        </Grid2>
      ) : (
        <div />
      )}

      {/* if the user IS subscribed: messages */}
      {isSubscribed ? (
        <Grid2 xs={12}>
          <Grid2Ct>
            <Messages />
          </Grid2Ct>
        </Grid2>
      ) : (
        <div />
      )}
    </Grid2Ct>
  );
};
